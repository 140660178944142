export const HomeObjOne = {
  id: "about",
  lightBg: false,
  lightText: false,
  LightTextDesc: true,
  topLine: "Me, myself and Ariel",
  headline: "",
  description:
    "Dedicated Software Engineer with a flair for problem-solving, I thrive on crafting elegant and efficient solutions. " +
    "With a strong background in .NET, Python, and React, I bring years of experience encompassing SaaS development. " +
    "My journey involves everything from designing and deploying web platforms to mastering microservices architecture. Now actively seeking new opportunities in New York, I offer adaptability, technical prowess, and a passion for driving success in dynamic environments. Let's build something great together!",
  buttonLabel: "Get CV",
  imgStart: false,
  alt: "",
  imgPath: require("../../images/pic2.jpg"),
  dark: true,
  primary: true,
  darkText: false,
};

export const HomeObjTwo = {
  id: "projects",
  lightBg: true,
  lightText: false,
  LightTextDesc: false,
  topLine: "Some of my personal softwares",
  description: "desc2 \n job1 \n job2",
  buttonLabel: "Go to my Github",
  imgStart: true,
  alt: "Car",
  imgPath: require("../../images/pic3.jpg"),
  dark: true,
  primary: true,
  darkText: true,
};

import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for initial animation
const rotateAndScale = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px) scale(0.5) rotate(-20deg);
  }
  70% {
    opacity: 1;
    transform: translateY(0) scale(1.2) rotate(20deg);
  }
  100% {
    transform: scale(1) rotate(0);
  }
`;

// Keyframes for hover animation
const hoverScale = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
`;

// Keyframes for glow animation
const glow = keyframes`
  0% {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.8);
  }
  50% {
    box-shadow: 0 0 20px 8px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.8);
  }
`;

// Styled component for the header
const AnimatedHeader = styled.h1`
  font-family: Arial, sans-serif;
  font-size: 4em;
  text-align: center;
  color: #333;
  animation: ${rotateAndScale} 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

  &:hover {
    animation: ${hoverScale} 0.5s ease-in-out both, ${glow} 1.5s ease-in-out infinite;
  }
`;

const BeautifulHeader = () => {
  return (
    <AnimatedHeader>
      Software Engineer
    </AnimatedHeader>
  );
};

export default BeautifulHeader;

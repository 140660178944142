import React from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for initial animation
const rotateInitial = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Keyframes for hover animation
const rotateHover = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

// Styled component for the header
const ExtremeAnimatedHeader = styled.h1`
  font-family: Impact, Charcoal, sans-serif;
  font-size: 4em;
  text-align: center;
  color: #006400; /* Dark green color */
  animation: ${rotateInitial} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  padding-top: 30px; /* Add padding of 20px to the top */
  &:hover {
    animation: ${rotateHover} 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
`;

const ExtremeBeautifulHeader = () => {
  return (
    <ExtremeAnimatedHeader>
      42 friendly here
    </ExtremeAnimatedHeader>
  );
};

export default ExtremeBeautifulHeader;

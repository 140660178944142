import React, { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
} from "./HeroElements";
import video from "../../videos/video1.mp4";

import BeautifulHeader from './CoolHeader'
import ExtremeBeautifulHeader from './CoolHeaderSmall'
const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="HeroSction">
      <HeroBg>
        <VideoBg autoPlay loop muted src={video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <BeautifulHeader/>
        <ExtremeBeautifulHeader/>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
